import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"

// Sections
import Hero from "sections/integrations/hero"
import FeaturedIntegration from "sections/integrations/featured-integrations"
import ReadyForDemo from "sections/integrations/ready-for-demo"

const Integrations = () => (
  <Layout showNewsletter={false}>
    <SEO title="Integrations | Arena Analytics" />
    <Hero />
    <FeaturedIntegration />
    <ReadyForDemo />
  </Layout>
)

export default Integrations

import React from "react"

// Libraries
import styled from "styled-components"

// Utils
// import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Button from "components/button"

// Icons
import breakpoints from "utils/breakpoints"
import IconLeft from "../../assets/icons/integrations/icon-left-integrations.inline.svg"
import IconRight from "../../assets/icons/integrations/icon-right-integrations.inline.svg"

const StyledReadyForDemo = styled.section`
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  width: 100%;

  .image-left {
    position: relative;
    left: -158px;
    top: -25px;
    height: 32px;

    ${breakpoints.medium`
    left: -158px;
    top: 55px;
    height: 64px;
    `}
  }
  .image-right {
    position: relative;
    left: calc(100% - 330px);
    bottom: -35px;
    height: 32px;

    ${breakpoints.medium`
    left: calc(100% - 316px);
    bottom: 55px;
    height: 64px;
    `}
  }
`

const ReadyForDemo = () => (
  <StyledReadyForDemo>
    <Container>
      <div className="row text-center">
        <div>
          <IconLeft className="image-left d-block d-md-block" />
          <h2 className="h1">
            Ready for a <span className="color--turquoise__primary">demo?</span>
          </h2>
        </div>
        <div className="mt-4">
          <Button level="primary" type="link" to="/request-demo">
            Book now
          </Button>
          <IconRight className="image-right d-block d-md-block" />
        </div>
      </div>
    </Container>
  </StyledReadyForDemo>
)

export default ReadyForDemo

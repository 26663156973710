import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

const StyledHero = styled.section`
  padding: 53px 0 64px 0;

  ${breakpoint.medium`
  padding: 56px 0 63px 0;
  `}
`

const Hero = () => (
  <StyledHero>
    <Container>
      <div className="row justify-content-md-between text-center text-md-start align-items-center">
        <div className="col-12 col-md-4">
          <h2 className="h1 maxi">
            <span className="color--turquoise__primary">Integrate</span>{" "}
            Seamlessly with any ATS
          </h2>
          <p className="text--large mt-4 font-weight--400">
            The Arena Analytics platform seamlessly integrates with your
            existing Applicant Tracking System (ATS), making it painless and
            easy to get up and running quickly.
          </p>
        </div>
        <div className="col-12 col-md-6 d-none d-md-flex">
          <StaticImage
            className="hero__image"
            src="../../assets/images/integrations/integrations-hero-image.png"
            alt=""
          />
        </div>
      </div>
    </Container>
  </StyledHero>
)

export default Hero

import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import breakpoints from "utils/breakpoints"
import { colors } from "utils/variables"

// Components
import Container from "components/container"

const StyledFeaturedIntegration = styled.section`
  background: ${colors.navy__extra_dark};
  padding: 62px 52px;
  color: ${colors.white};

  ${breakpoints.medium`
    padding: 100px 112px;
  `}

  .title {
    margin-bottom: 45px;

    ${breakpoints.medium`
      margin-bottom: 24px;

    `}
  }

  .subtitle {
    margin-bottom: 35px;

    ${breakpoints.medium`
      margin-bottom: 84px;
    `}
  }

  .card {
    background: ${colors.white};
    border-radius: 16px;
    max-width: 352px;
    width: 100%;
    height: 273px;
    margin-bottom: 35px;
    padding-right: 43px;
    padding-left: 43px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    ${breakpoints.medium`
      height: 336px;
    `}

    .image {
      width: 80%;
      ${breakpoints.medium`
        width: 70%;
      `}
    }

    &:hover {
      transition: all 0.1s ease;

      .image {
        position: absolute;
        display: none;
      }

      .card__info {
        position: relative;
        visibility: visible;
        justify-content: center;
      }
    }

    .card__info {
      position: absolute;
      visibility: hidden;
      .card__info-title {
        margin-bottom: 32px;
      }
    }
  }

  .final-text {
    ${breakpoints.medium`
      margin-top: 100px;
    `}
  }
`

const FeaturedIntegration = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulIntegrationsPage(title: { eq: "Featured Integrations" }) {
        integrations {
          logo {
            gatsbyImageData
          }
          name
          description {
            description
          }
        }
      }
    }
  `)

  return (
    <StyledFeaturedIntegration>
      <Container>
        <div className="row justify-content-center">
          <h1 className="h1 text-center title">
            Featured{" "}
            <span className="color--turquoise__light">Integrations</span>
          </h1>
          <div className="col-12 col-md-8 subtitle">
            <p className="text-center text--large color--silver__light">
              See below for a representative sample of some of the leading ATS
              solutions with which we’ve already integrated.
            </p>
          </div>
        </div>
        <div className="row justify-content-center justify-content-md-between">
          {data.contentfulIntegrationsPage.integrations.map((integration) => (
            <div className="card col-12 col-sm-4 col-lg-4">
              <div className="image">
                <GatsbyImage image={getImage(integration.logo)} alt="" />
              </div>

              <div className="card__info">
                <div className="text-center h3 color--navy__extra_dark card__info-title">
                  {integration.name}
                </div>
                <div className="text-center text--small color--navy__extra_dark">
                  {integration.description.description}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row justify-content-center">
          <div className="col-12">
            <p className="final-text text-center text--small color--silver__light">
              Images on this site may depict third parties by name, trademark,
              product or otherwise. Such depiction is not intended, nor should
              be construed, to suggest any endorsement by such third party of
              Arena, its products or programs. Images shown are intended only to
              demonstrate the performance of Arena products.
            </p>
          </div>
        </div>
      </Container>
    </StyledFeaturedIntegration>
  )
}

export default FeaturedIntegration
